import { Slot } from '@radix-ui/react-slot';
import { type ForwardedRef, type PropsWithChildren, forwardRef } from 'react';

import {
  type RainbowSprinkles,
  rainbowSprinkles,
} from '../../rainbow-sprinkles.css.js';
import { sprinkles } from '../../sprinkles.css.js';
import type { ElementProps } from '../../types.js';
import { joinClassnames } from '../../utilities/internal.js';

export interface FlexProps
  extends PropsWithChildren<ElementProps<'div'> & RainbowSprinkles> {
  asChild?: boolean | undefined;
}

function _Flex(
  { asChild, children, ...props }: FlexProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const Component = asChild ? Slot : 'div';
  const { className, style, otherProps } = rainbowSprinkles(props);

  return (
    <Component
      className={joinClassnames([sprinkles({ display: 'flex' }), className])}
      ref={ref}
      style={style}
      {...otherProps}
    >
      {children}
    </Component>
  );
}

export const Flex = forwardRef(_Flex);
